<template>
  <div
    class="stripe-element--card v-input theme--light v-text-field v-text-field--is-booted"
  >
    <div class="v-input__control w-100">
      <div class="v-input__slot w-100">
        <div class="v-text-field__slot w-100">
          <label
            for="input-122"
            class="v-label v-label--active theme--light"
            style="left: 0px; right: auto; position: absolute"
            >{{ $vuetify.lang.t('Credit Card') }}</label
          >
          <div id="stripe-card-mount" class="w-100"></div>
        </div>
      </div>
      <div class="v-text-field__details">
        <div class="v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div v-if="error" class="v-messages__message">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { captureException } from '@sentry/vue';
import { style, PAYMENT_TYPES } from '../tools/stripeConf';

/** @implements { element: StripeElement } */
export default {
  props: {
    error: {
      default: '',
    },

    stripe: {
      type: Object,
      required: true,
    },

    elements: {
      type: Object,
      required: true,
    },

    value: {
      type: Object,
    },

    pk: {
      type: String,
      required: true,
    },
    // // elemnt specific options
    classes: {
      type: Object,
      default: () => ({
        // base: 'v-text-field',
        focus: 'v-input--has-state',
        invalid: 'error--text',
        complete: 'v-input--is-label-active v-input--is-dirty'
      }),
    },

    hidePostalCode: { type: Boolean, default: true },
    iconStyle: {
      type: String,
      default: 'default',
      validator: (value) => ['solid', 'default'].includes(value),
    },
    hideIcon: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      initialized: false,
      element: null,
    };
  },

  computed: {
    syncedError: {
      get() {
        return this.error;
      },
      set(val) {
        this.$emit('update:error', val);
      },
    },

    syncedModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    change(e) {
      this.$log.debug('LOCO', e);

      if (e.error) {
        this.syncedError = e.error.message;
      } else {
        this.syncedError = '';
      }

      if (e.complete) {
        this.$log.debug('Update model');
        this.syncedModel = this.element;
      } else {
        this.$emit('element-change', e);
        this.syncedModel = undefined;
      }
    },
    ready(e) {
      this.$log.debug('LOCO', e);
      this.$emit('element-ready', e);
    },
    focus(e) {
      this.$log.debug('LOCO', e);
      this.$emit('element-focus', e);
    },
    blur(e) {
      this.$log.debug('LOCO', e);
      this.$emit('element-blur', e);
    },
    escape(e) {
      this.$log.debug('LOCO', e);
      this.$emit('element-escape', e);
    },
    click(e) {
      this.$log.debug('LOCO', e);
      this.$emit('element-click', e);
    },
  },

  async mounted() {
    const createOptions = {
      classes: this.classes,
      style,
      hidePostalCode: this.hidePostalCode,
      hideIcon: this.hideIcon,
      disabled: this.disabled,
    };

    try {
      this.element = this.elements.create(PAYMENT_TYPES.CARD, createOptions);
      this.element.mount('#stripe-card-mount');

      this.element.on('change', this.change);
      this.element.on('ready', this.ready);
      this.element.on('focus', this.focus);
      this.element.on('blur', this.blur);
      this.element.on('escape', this.escape);
      this.element.on('click', this.click);

      this.initialized = true;
    } catch (error) {
      captureException(error);
    }

    return;
  },

  beforeDestroy() {
    this.element.destroy();
  },
};
</script>

<style scoped>
.stripe-element--card {
  min-width: 20em;
}

.w-100 {
  width: 100%;
}
</style>
